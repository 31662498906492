import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Box,
  TextField,
  Typography,
  Grid,
  Button,
  IconButton
} from '@mui/material';

import {
  LocalizationProvider,
  DatePicker
} from '@mui/lab';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';


const AddVesting = ({
  web3,
  accounts,
  smartContract,
  refreshBalance,
  gasPrice,
  gasCost
}) => {

  const [showSuccess, setShowSuccess] = useState(false);
  const [vestingStartTime, setVestingStartTime] = useState(moment(new Date()).add(3, 'M').toDate());
  //const [vestingStartTime, setVestingStartTime] = useState(new Date());

  // console.log('gas price: ' + gasPrice);
  // console.log('gas cost: ' + gasCost);

  const fRef = React.createRef();

  const initialValues = {
    account: '',
    amount: '',
    vestingPeriod: ''
  };

  const validationSchema = Yup.object().shape({
    account: Yup.string().max(255).required('Account is required'),
    amount: Yup.number().min(0.01, 'Min. Vesting Amount is 0.1').required('Vesting Amount is required'),
    vestingPeriod: Yup.string().required('Vesting Period is required')
  });

  async function handleVestingStartChange(date) {
    setVestingStartTime(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  };


  const onSubmit = async (
    {
      account,
      amount,
      vestingPeriod
    }, { setSubmitting, resetForm }
  ) => {
    account = account.trim();

    let res = null;

    amount = web3.utils.toWei(amount.toString(), 'ether');
    res = await submitToSmartContract(account, amount, vestingPeriod);

    setSubmitting(false);

    if (res !== null && res.transactionHash !== null) {
      setShowSuccess(true);

      setTimeout(() => {
        refreshBalance();
        resetForm();
      }, 1000);
    }
    
  };

  const submitToSmartContract = async (account, amount, vestingPeriod) => {
    let startVestingTime = moment(vestingStartTime).unix();
    let endVestingTime = await periodToReleaseTime(vestingPeriod);

    if (!endVestingTime) {
      return false;
    }
    endVestingTime = parseInt(endVestingTime / 1000);

    if (gasPrice === 0 || gasCost === 0) {
      alert('Invalid gas cost');
      return null;
    }
    
    let res = await smartContract.methods.addVesting(
      account,
      amount,
      startVestingTime,
      endVestingTime
    ).send({from: accounts[0], gas: gasCost.toString(), gasPrice: gasPrice.toString()});
  

    return res;
  };

  const periodToReleaseTime = async (period) => {
    let releaseTime = moment(vestingStartTime);

    switch (period) {
      case '1h':
        releaseTime = releaseTime.add(1, 'hour');
        break;
      
      case '2h':
        releaseTime = releaseTime.add(2, 'hour');
        break;

      case '1d':
        releaseTime = releaseTime.add(1, 'day');
        break;

      case '2d':
        releaseTime = releaseTime.add(2, 'day');
        break;

      case '1m':
        releaseTime = releaseTime.add(1, 'month');
        break;

      case '2m':
        releaseTime = releaseTime.add(2, 'month');
        break;

      case '5m':
        releaseTime = releaseTime.add(5, 'month');
        break;

      case '6m':
        releaseTime = releaseTime.add(6, 'month');
        break;

      case '9m':
        releaseTime = releaseTime.add(9, 'month');
        break;

      case '10m':
        releaseTime = releaseTime.add(10, 'month');
        break;

      case '11m':
        releaseTime = releaseTime.add(11, 'month');
        break;

      case '12m':
        releaseTime = releaseTime.add(12, 'month');
        break;

      case '13m':
        releaseTime = releaseTime.add(13, 'month');
        break;
      
      case '14m':
        releaseTime = releaseTime.add(14, 'month');
        break;

      case '15m':
        releaseTime = releaseTime.add(15, 'month');
        break;

      case '16m':
        releaseTime = releaseTime.add(16, 'month');
        break;

      case '17m':
        releaseTime = releaseTime.add(17, 'month');
        break;

      case '18m':
        releaseTime = releaseTime.add(18, 'month');
        break;

      case '19m':
        releaseTime = releaseTime.add(19, 'month');
        break;

      case '20m':
        releaseTime = releaseTime.add(20, 'month');
        break;

      case '21m':
        releaseTime = releaseTime.add(21, 'month');
        break;

      case '22m':
        releaseTime = releaseTime.add(22, 'month');
        break;
      
      case '23m':
        releaseTime = releaseTime.add(23, 'month');
        break;

      case '24m':
        releaseTime = releaseTime.add(24, 'month');
        break;

      case '30m':
        releaseTime = releaseTime.add(30, 'month');
        break;

      case '33m':
        releaseTime = releaseTime.add(33, 'month');
        break;

      case '36m':
        releaseTime = releaseTime.add(36, 'month');
        break;
    
      default:
        return null;
    }

    return releaseTime;
  };

  return (
    <>
      <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Add New Vesting
        </Typography>
        <Typography
          color="textPrimary"
          variant="body2"
        >
          1. Send the required amount of tokens to the Smart Contract.<br/>
          2. Add new Vesting for an account.
        </Typography>
      </Box>

      {showSuccess === true && <Alert severity="success" action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowSuccess(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >New vesting rule has been added successfully!</Alert>}
      
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={(f) => { fRef.current = f; }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <TextField
              error={Boolean(touched.account && errors.account)}
              fullWidth
              helperText={touched.account && errors.account}
              label="Account"
              margin="normal"
              name="account"
              required
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.account}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              helperText={touched.amount && errors.amount}
              label="Amount"
              margin="normal"
              name="amount"
              required
              onBlur={e => {
                handleBlur(e);
              }}
              onChange={handleChange}
              value={values.amount}
              variant="outlined"
            />
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Vesting Start Date"
                      value={vestingStartTime}
                      onChange={handleVestingStartChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Vesting Period"
                    name="vestingPeriod"
                    required
                    select
                    SelectProps={{ native: true }}
                    onChange={e => {
                      handleChange(e);
                    }}
                    value={values.vestingPeriod}
                    variant="outlined"
                  >
                    <option
                      key=""
                      value=""
                    ></option>
                    <option
                      key="1h"
                      value="1h"
                    >
                      1 Hour
                    </option>
                    <option
                      key="2h"
                      value="2h"
                    >
                      2 Hours
                    </option>
                    <option
                      key="1d"
                      value="1d"
                    >
                      1 Day
                    </option>
                    <option
                      key="2d"
                      value="2d"
                    >
                      2 Days
                    </option>
                    <option
                      key="1m"
                      value="1m"
                    >
                      1 Month
                    </option>
                    <option
                      key="2m"
                      value="2m"
                    >
                      2 Months
                    </option>
                    <option
                      key="5m"
                      value="5m"
                    >
                      5 Months
                    </option>
                    <option
                      key="6m"
                      value="6m"
                    >
                      6 Months
                    </option>
                    <option
                      key="9m"
                      value="9m"
                    >
                      9 Months
                    </option>
                    <option
                      key="10m"
                      value="10m"
                    >
                      10 Months
                    </option>
                    <option
                      key="11m"
                      value="11m"
                    >
                      11 Months
                    </option>
                    <option
                      key="12m"
                      value="12m"
                    >
                      12 Months
                    </option>
                    <option
                      key="13m"
                      value="13m"
                    >
                      13 Months
                    </option>
                    <option
                      key="14m"
                      value="14m"
                    >
                      14 Months
                    </option>
                    <option
                      key="15m"
                      value="15m"
                    >
                      15 Months
                    </option>
                    <option
                      key="16m"
                      value="16m"
                    >
                      16 Months
                    </option>
                    <option
                      key="17m"
                      value="17m"
                    >
                      17 Months
                    </option>
                    <option
                      key="18m"
                      value="18m"
                    >
                      18 Months
                    </option>
                    <option
                      key="19m"
                      value="19m"
                    >
                      19 Months
                    </option>
                    <option
                      key="20m"
                      value="20m"
                    >
                      20 Months
                    </option>
                    <option
                      key="21m"
                      value="21m"
                    >
                      21 Months
                    </option>
                    <option
                      key="22m"
                      value="22m"
                    >
                      22 Months
                    </option>
                    <option
                      key="23m"
                      value="23m"
                    >
                      23 Months
                    </option>
                    <option
                      key="24m"
                      value="24m"
                    >
                      24 Months
                    </option>
                    <option
                      key="30m"
                      value="30m"
                    >
                      30 Months
                    </option>
                    <option
                      key="33m"
                      value="33m"
                    >
                      33 Months
                    </option>
                    <option
                      key="36m"
                      value="36m"
                    >
                      36 Months
                    </option>
                  </TextField>
                </Grid>
              </Grid>
              
            </Box>
            
            <Box my={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Add Vesting
              </Button>
            </Box>
          </form>
        )}
      </Formik>

    </>
  );
};


export default AddVesting;
