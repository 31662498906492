import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';

const VestingPlan = ({
  web3,
  accounts,
  records,
  smartContract,
  accountAddress,
  released
}) => {

  const [releasableAmounts, setReleasableAmounts] = useState([]);
  const [payouts, setPayouts] = useState([]);

  useEffect(() => {
    if (records.length > 0) {
      syncData();
    } else {
      setPayouts([]);
    }
  }, [records]);

  const syncData = async () => {
    let releasables = [];
    setPayouts([]);

    for (let i in records) {
      try {
        releasables[i] = await smartContract.methods.releasableAmount(accountAddress, i).call();
      } catch (error) {
        console.log(error);
      }
    }
    setReleasableAmounts(releasables);

    let withdrawals = null;
    try {
      withdrawals = await smartContract.methods.payouts(accountAddress).call();
    } catch (error) {
      console.log(error);
    }

    if (withdrawals && withdrawals.length > 0) {
      let pOuts = [];
      
      for (let i in withdrawals) {
        pOuts.unshift(withdrawals[i]);
      }

      setPayouts(pOuts);
    }
  }

  const releaseProcess = async (key, amount) => {
    let gasPrice = 0;
    let gasCost = 0;

    try {
      gasPrice = await web3.eth.getGasPrice();
      gasPrice = parseInt(gasPrice);

      gasCost = await smartContract.methods.release(accountAddress, key, amount.toString()).estimateGas({from: accounts[0]});
      gasCost = parseInt(gasCost);

    } catch (error) {
      console.log('Could not get gas price');
    }
    
    // console.log('price: ' + gasPrice);
    // console.log('cost: ' + gasCost);

    if (gasPrice === 0 || gasCost === 0) {
      alert('Invalid gas cost');
      return null;
    }

    // console.log('account: ' + accountAddress);
    // console.log('set: ' + key);
    // console.log('amount: ' + amount.toString());
    
    try {
      await smartContract.methods.release(accountAddress, key, amount.toString()).send({from: accounts[0], gas: gasCost.toString(), gasPrice: gasPrice.toString()});
    } catch (error) {
      console.log(error);
    }
    
    setTimeout(() => {
      released();
      syncData();
    }, 1000);
  }


  return (
    <>
      {records.length > 0 && <>
      {records.map((r, i) => (
        <Box key={i} pt={2}>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Total:{' '}
            <NumberFormat value={web3.utils.fromWei(r.totalAmount.toString(), 'ether')} displayType={'text'} thousandSeparator={true} /> BSGG
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Total Releasable:{' '}
            {releasableAmounts[i] > 0 && <NumberFormat value={web3.utils.fromWei((releasableAmounts[i]).toString(), 'ether')} displayType={'text'} thousandSeparator={true} />} BSGG
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Total Released:{' '}
            <NumberFormat value={web3.utils.fromWei(r.paidAmount.toString(), 'ether')} displayType={'text'} thousandSeparator={true} /> BSGG
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Vesting Starts:{' '}
            {moment(parseInt(r.strartTime) * 1000).format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body2"
          >
            Vesting Ends:{' '}
            {moment(parseInt(r.endTime) * 1000).format('YYYY-MM-DD HH:mm')}
          </Typography>

          {releasableAmounts[i] > 0 && <Box my={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              variant="contained"
              onClick={() => { releaseProcess(i, releasableAmounts[i]); }}
            >
              Release {web3.utils.fromWei((releasableAmounts[i]).toString(), 'ether')} BSGG
            </Button>
          </Box>}
        </Box>
        
      ))}

      {payouts.length > 0 && <>
        <Box pt={2}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Payouts
          </Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts.map((payout) => (
              <TableRow
                hover
                key={payout.time}
              >
                <TableCell>
                  {moment(payout.time * 1000).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell>
                  <NumberFormat value={web3.utils.fromWei(payout.amount, 'ether')} displayType={'text'} thousandSeparator={true} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>}

      </>}

    </>
  );
};


export default VestingPlan;
