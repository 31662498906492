import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';

import Button from '@mui/material/Button';

const Connect = ({
  setWeb3,
  accounts,
  setAccounts,
  connected,
  setConnected,
  setShowError,
  setInvalidNetwork
}) => {

  const [provider, setProvider] = useState(null);

  useEffect(() => {
    connectWeb3();
  }, []);


  const connectWeb3 = async () => {
    const providerLoc = await detectEthereumProvider();
    providerLoc.on('accountsChanged', connectWeb3);
    providerLoc.on('chainChanged', connectWeb3);
    providerLoc.on('disconnect', disconnectWeb3);

    setProvider(providerLoc);

    if (!providerLoc) {
      alert('Please install Metamask');
      return false;
    }


    const chainId = await providerLoc.request({ method: 'net_version' });

    if (parseInt(chainId) !== parseInt(process.env.REACT_APP_PROVIDER_NETWORK)) {
      setInvalidNetwork(true);
      setShowError(true);
      setConnected(false);
      setAccounts([]);
      return false;
    }
    setInvalidNetwork(false);

    let isConnectedToWallet = await providerLoc.isConnected();

    if (isConnectedToWallet !== true) {
      disconnectWeb3();
      return false;
    }

    // If wallet is connected
    let availableAccounts = await providerLoc.request({ method: 'eth_accounts' });

    if (availableAccounts === null || availableAccounts.length === 0) {
      disconnectWeb3();
      return false;
    }

    let accs = [];
    try {
      //accs = await providerLoc.request({ method: 'eth_accounts' });
      let locAccounts = localStorage.getItem('accounts');

      if (locAccounts !== null) {
        accs = JSON.parse(locAccounts);
      } else {
        setConnected(false);
        setAccounts([]);
      }
    } catch (error) {
      if (error.code === 4001) {
        // User rejected request
        setConnected(false);
        setAccounts([]);
      }
    }

    if (accs !== null && accs.length !== 0) {
      setAccounts(accs);
      setConnected(true);
      
      const web3Loc = new Web3(providerLoc);
      setWeb3(web3Loc);
    } else {
      setConnected(false);
      setAccounts([]);
    }
  };

  const disconnectWeb3 = () => {
    localStorage.setItem('accounts', null);
    setConnected(false);
    setAccounts([]);
  }

  const requestConnection = async () => {
    try {
      let accs = await provider.request({ method: 'eth_requestAccounts' });
      if (accs !== null && accs.length >0) {
        setAccounts(accs);
        localStorage.setItem('accounts', JSON.stringify(accs));
        setConnected(true);
      }
    } catch (error) {
      if (error.code === 4001) {
        // User rejected request
        setConnected(false);
      }
      return false;
    }
  };

  const addressToLabel = (address) => {
    const addressLength = address.length;

    let res = address.substring(0, 5) + '...' + address.substring(addressLength-4, addressLength);
    return res;
  };


  return (
    <>
     {connected !== true && <Button size="small" color="primary" variant="contained"
     onClick={() => {
      connectWeb3();
      requestConnection();
    }}
    >Connect Wallet</Button>}
    {connected === true && accounts !== null && <Button size="small" color="primary" variant="contained"
    onClick={() => {
      disconnectWeb3();
    }}
    >{addressToLabel(accounts[0])}</Button>}
    </>
  );
};

export default Connect;
