import React, { useState, useEffect } from 'react';

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';


const LastVested = ({
  smartContract,
  viewVestingAccount,
  refreshLastVested
}) => {

  const [vestedAccounts, setVestedAccounts] = useState([]);

  useEffect(() => {
    if (smartContract) {
      syncData();
    }
  }, [smartContract, refreshLastVested]);


  const syncData = async () => {
    let vestedAccountsTotal = 0;
    let vestedAccs = null;

    try {
      vestedAccountsTotal = await smartContract.methods.vestingAccountsTotal().call();
    } catch (error) {
      console.log(error);
    }

    if (vestedAccountsTotal > 1200) {
      vestedAccountsTotal = 1200;
    }

    if (vestedAccountsTotal > 0) {
      vestedAccs = await smartContract.methods.vestingAccountsGet(0, vestedAccountsTotal).call();

      if (vestedAccs.length > 0) {
        let va = [];

        for (let i in vestedAccs) {
          va.unshift(vestedAccs[i]);
        }

        setVestedAccounts(va);
      }
    }

  }

  return (
    <>
      {vestedAccounts.length > 0 && <>
      <Box pt={2}>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Latest Vested Accounts
        </Typography>
      </Box>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Account
              </TableCell>
              <TableCell>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vestedAccounts.map((va, index) => (
              <TableRow
                hover
                key={index}
              >
                <TableCell>
                  {va}
                </TableCell>
                <TableCell>
                <Button
                  color="primary"
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => { viewVestingAccount(va); }}
                >
                  View
                </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </>}
    </>
  );
};


export default LastVested;
